<script>
	import MotivationIntro from '../components/MotivationIntro.svelte';
	import HealthResources from '../components/HealthResources.svelte';
</script>

<MotivationIntro />
<HealthResources />

<footer>
	<div class="container">
		<div>
			Icons from
			<a
				href="https://github.com/feathericons/feather/blob/main/LICENSE"
				target="_blank"
				rel="noreferrer noopener">Feather icons</a
			> by Cole Bemis.
		</div>
		<div>
			Emojis designed by
			<a href="https://openmoji.org/" target="_blank" rel="noreferrer noopener">OpenMoji</a>
			– the open-source emoji and icon project. License:
			<a
				href="https://creativecommons.org/licenses/by-sa/4.0/"
				target="_blank"
				rel="noreferrer noopener">CC BY-SA 4.0</a
			>
		</div>
	</div>
</footer>

<style>
	footer {
		background: url('/noise.webp') hsl(212, 57%, 92%);
		font:
			0.6em 'Atkinson Hyperlegible',
			sans-serif;
		padding: 1em;
		background-size: 36px;
		color: hsl(212, 57%, 60%);
	}

	.container {
		margin: 0 auto;
		width: 500px;
		max-width: 100%;
	}

	a {
		color: inherit;
	}
</style>
