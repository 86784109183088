<svelte:head>
	<meta name="theme-color" content="#2b6db9" />
	<link rel="preload" href="/noise.webp" as="image" />
</svelte:head>

<section>
	<div class="container">
		<div class="midtext">Tygerberg Campus Health Day 2024</div>
		<svg class="sun-svg" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M101.6 84.2125L102.984 83.1748L101.761 81.9518L56.8357 37.0268L55.775 35.9662L54.7143 37.0268L37.0393 54.7018L35.9788 55.7624L37.0392 56.823L81.9642 101.761L83.1883 102.985L84.2258 101.599C89.1651 95.0009 95.0138 89.1522 101.6 84.2125ZM165.287 67.9848L167 68.2303V66.5V3V1.5H165.5H140.5H139V3V66.5V68.2303L140.713 67.9848C144.772 67.4032 148.846 67 153 67C157.154 67 161.228 67.4032 165.287 67.9848ZM221.775 101.588L222.813 102.971L224.036 101.748L268.961 56.8105L270.021 55.7499L268.961 54.6893L251.286 37.0143L250.225 35.9535L249.164 37.0145L204.239 81.952L203.017 83.1749L204.4 84.2125C210.986 89.1522 216.835 95.0012 221.775 101.588ZM67.9848 140.713L68.2303 139H66.5H3H1.5V140.5V165.5V167H3H66.5H68.2303L67.9848 165.287C67.4032 161.228 67 157.154 67 153C67 148.846 67.4032 144.772 67.9848 140.713ZM204.413 221.775L203.029 222.813L204.252 224.036L249.177 268.948L250.237 270.008L251.298 268.949L268.985 251.274L270.047 250.213L268.986 249.152L224.061 204.227L222.838 203.004L221.8 204.387C216.848 210.986 210.999 216.835 204.413 221.775ZM84.2122 204.4L83.1744 203.017L81.9518 204.239L37.0393 249.152L35.9787 250.213L37.0393 251.273L54.7143 268.948L55.775 270.009L56.8357 268.948L101.748 224.036L102.971 222.813L101.588 221.775C95.0013 216.835 89.1647 210.999 84.2122 204.4ZM239.5 139H237.77L238.015 140.713C238.597 144.772 239 148.846 239 153C239 157.154 238.597 161.228 238.015 165.287L237.77 167H239.5H303H304.5V165.5V140.5V139H303H239.5ZM140.713 238.015L139 237.77V239.5V303V304.5H140.5H165.5H167V303V239.5V237.77L165.287 238.015C161.228 238.597 157.154 239 153 239C148.846 239 144.772 238.597 140.713 238.015ZM153 217C188.353 217 217 188.353 217 153C217 117.647 188.353 89 153 89C117.647 89 89 117.647 89 153C89 188.353 117.647 217 153 217Z"
				fill="#FFFA19"
				stroke="#012c5d"
				stroke-width="3"
			/>
		</svg>

		<h1 class="motivation-heading">motivational messages</h1>
		<p class="midtext">
			See your own motivational message and pick which message the next person gets!
		</p>

		<a
			href="/spin"
			class="spin-button"
			data-sveltekit-preload-code="eager"
			data-sveltekit-replacestate={true}>spin the wheel!</a
		>
		<div class="madewith">made with ❤️ by MBChB II</div>
	</div>
</section>

<style>
	section {
		background: url('/noise.webp') #3c7eca;
		background-size: 72px;
		font: 1em 'Atkinson Hyperlegible';
		text-align: center;
	}

	.container {
		margin: 0 auto;
		padding: 2rem;
		width: 500px;
		max-width: 100%;
	}

	.sun-svg {
		width: 280px;
		height: 280px;
		filter: drop-shadow(0 0 56px #fffa199e);
		margin-bottom: 2rem;
	}

	@media (prefers-reduced-motion: no-preference) {
		.sun-svg {
			animation: sun-breathe 1.5s ease-in-out infinite alternate;
		}
	}

	.motivation-heading {
		font: 2.7rem/1 'Patua One';
		-webkit-text-stroke: 1px #012c5d;
		color: #fffa19;
		margin: 0 0 1rem 0;
	}

	.midtext {
		color: #bbdbff;
		line-height: 1.5;
		text-wrap: balance;
		margin-bottom: 2em;
	}

	.spin-button {
		display: inline-block;
		font:
			1em/1 'Patua One',
			sans-serif;
		text-decoration: none;
		color: #012c5d;
		background: #fffa19;
		padding: 1em 1.6em;
		border-radius: 36px;
		border: 2px solid #012c5d;
		transition: transform 0.2s ease-out;
		box-shadow: #fffa1977 0 6px 36px -12px;
		margin-bottom: 2em;
	}

	.spin-button:hover {
		transform: scale(1.05);
	}

	.madewith {
		font-size: 0.8em;
		text-align: left;
		color: #7cb9ff;
	}

	@keyframes sun-breathe {
		from {
			transform: scale(1);
		}

		to {
			transform: scale(0.9);
		}
	}
</style>
