<section class="resources">
	<div class="container">
		<h1>Useful mental health resources:</h1>
		<p>
			Want to expand your knowledge? Mental health is not a concern limited to those with medical
			diagnoses. Each of us has room to grow :)
		</p>
		<div class="links">
			<a
				class="resource-link"
				href="https://talk2gether.nhs.uk/quick-self-assessment/"
				target="_blank"
				rel="noreferrer noopener"
			>
				<div>GAD-7 & PHQ-9 Anxiety and Depression risk self-assessment</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a
				class="resource-link"
				href="https://www.ncbi.nlm.nih.gov/books/NBK559078/"
				target="_blank"
				rel="noreferrer noopener"
			>
				<div>StatPearls: Major Depressive Disorder</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a
				class="resource-link"
				href="https://www.ncbi.nlm.nih.gov/books/NBK441870/"
				target="_blank"
				rel="noreferrer noopener"
			>
				<div>StatPearls: Generalised Anxiety Disorder</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a
				class="resource-link"
				href="https://podcasts.apple.com/za/podcast/psychiatry-boot-camp/id1671902940"
				target="_blank"
				rel="noreferrer noopener"
			>
				<div>Podcast: Psychiatry Bootcamp</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a
				class="resource-link"
				href="https://www.nimh.nih.gov/health/topics"
				target="_blank"
				rel="noreferrer noopener"
			>
				<div>National Institute of Mental Health: Mental Disorder Information</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
		</div>
	</div>
</section>

<section class="contact">
	<div class="container">
		<h1>Feeling worried about yourself?</h1>
		<p>
			Reach out! Life can be difficult sometimes, even for extended periods of time. That doesn’t
			mean it will always be.
		</p>
		<div class="links">
			<a class="resource-link" href="mailto:supportus@sun.ac.za">
				<div>
					SU Centre for Student Counselling & Development
					<div class="contactdetails">supportus@sun.ac.za</div>
				</div>

				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a class="resource-link" href="mailto:studysuccess@sun.ac.za​">
				<div>
					SU Unit for Academic Counselling & Development
					<div class="contactdetails">studysuccess@sun.ac.za​</div>
				</div>

				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a class="resource-link" href="tel:0800567567">
				<div class="link-container">
					<img alt="arrow" src="/phone.svg" />
					<div>
						SADAG Suicide Crisis Helpline
						<div class="contactdetails">0800 567 567</div>
					</div>
				</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a class="resource-link" href="tel:0219277020">
				<div class="link-container">
					<img alt="arrow" src="/phone.svg" />
					<div>
						SU Unit for Psychotherapeutic & Support Services
						<div class="contactdetails">021 927 7020</div>
					</div>
				</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
			<a class="resource-link" href="tel:0800121314">
				<div class="link-container">
					<img alt="arrow" src="/phone.svg" />
					<div>
						Substance Abuse Helpline
						<div class="contactdetails">0800 12 13 14</div>
					</div>
				</div>
				<img alt="arrow" src="/arrow.svg" />
			</a>
		</div>
	</div>
</section>

<style>
	.resources {
		background: url('/noise.webp') hsl(212, 57%, 92%);
		color: #001d66;
		background-size: 36px;
	}

	.contact {
		background: url('/noise.webp') hsl(212, 57%, 90%);
		color: #001d66;
		background-size: 36px;
	}

	.container {
		margin: 0 auto;
		padding: 2rem;
		width: 500px;
		max-width: 100%;
	}

	h1 {
		font: 2em/1 'Patua One';
		margin: 0;
		padding-top: 1em;
		border-top: 2px solid;
	}

	p {
		text-wrap: balance;
	}

	.resource-link {
		display: flex;
		padding: 1.2em 0;
		border-bottom: 1px solid #001d6641;
		justify-content: space-between;
		font:
			bold 1.2em/1.2 'Atkinson Hyperlegible',
			sans-serif;
		color: inherit;
		gap: 1em;
		text-decoration: none;
	}

	.resource-link:last-child {
		border-bottom: none;
	}

	.resource-link img {
		display: block;
		flex-shrink: 0;
		object-fit: contain;
		width: 24px;
		height: 24px;
	}

	.contactdetails {
		font: 0.8em/1 'Atkinson Hyperlegible';
		margin-top: 0.5em;
		color: #001d66aa;
	}

	.link-container {
		display: flex;
		gap: 0.8em;
	}

	.link-container img {
		margin-top: 4px;
	}
</style>
